import { createAsyncThunk } from '@reduxjs/toolkit';
import queryString from 'query-string';
import apiKey from '../../../utils/axiosInstance';
export const getNotifications = createAsyncThunk('notifications/getNotifications', async ({ limit, page }, ThunkApi) => {
    try {
        const query = queryString.stringify({ limit, page });
        const response = await apiKey.get(`/notifications?${query}`);
        const data = response.data;
        return data;
    }
    catch (error) {
        return ThunkApi.rejectWithValue(error);
    }
});
export const markAsRead = createAsyncThunk('notifications/markAsRead', async ({ id }, ThunkApi) => {
    try {
        const response = await apiKey.post(`/notifications/${id}/seen`);
        const data = response.data;
        return data;
    }
    catch (error) {
        return ThunkApi.rejectWithValue(error);
    }
});
export const markAllSeen = createAsyncThunk('notifications/markAsRead', async (_, ThunkApi) => {
    try {
        const response = await apiKey.post(`/notifications/seen`);
        const data = response.data;
        return data;
    }
    catch (error) {
        return ThunkApi.rejectWithValue(error);
    }
});
export const markAllUnseen = createAsyncThunk('notifications/markAsRead', async (_, ThunkApi) => {
    try {
        const response = await apiKey.get(`/notifications/seen`);
        const data = response.data;
        return data;
    }
    catch (error) {
        return ThunkApi.rejectWithValue(error);
    }
});
